import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Layout from 'components/layout';
import Head from 'components/head';
import ilustracion from '../../static/404Illustration.svg';

const NotFound = () => (
  <Layout>
    <Head pageTitle="404 - No Encontrado - Visualma" />
    <Flex
      alignContent="center"
      alignItems="center"
      flexDirection="column"
      overflow="hidden"
    >
      <img
        src={ilustracion}
        style={{ maxWidth: '90%', width: '650px', margin: '2em auto 1em' }}
        alt="404, No encontramos esa página"
      />
      <Box
        style={{ color: 'var(--accent-color)', fontSize: 'calc(1.0em + 1vw)' }}
        m={['1em auto 2em auto', '1em auto 2em auto']}
        textAlign="center"
      >
        No encontramos esa página
      </Box>
    </Flex>
  </Layout>
);

export default NotFound;
